{
  "ariaLabel": {
    "viewDetails": "View details",
    "downloadDataAssets": "Download data assets"
  },
  "topMenu": {
    "dihTitle": "Data Intelligence Hub",
    "DIHOrgRegistration": "Data Intelligence Hub - Organization Registration",
    "dihSubTitle": "Extract value from data securely",
    "logout": "Log out",
    "myAccount": "My account",
    "myOrganization": "My organization"
  },
  "userLogoutAlert": {
    "title": "Are you sure you want to log out?",
    "back": "Back",
    "logout": "Log out",
    "myOrganization": "My organization"
  },
  "sideMenu": {
    "dashboard": "Dashboard",
    "connectors": "Connectors",
    "myConnectors": "My connectors",
    "dataspaces": "Dataspaces",
    "discover": "Discover",
    "manage": "Manage",
    "marketplace": "Marketplace",
    "myDataspaces": "My memberships",
    "browseDataspaces": "Browse dataspaces",
    "organizations": "Organizations",
    "build": "Build",
    "ownedDataspaces": "My dataspaces",
    "collapse": "Collapse sidebar",
    "participate": "Participate",
    "getVerified": "Get verified",
    "workbench": "Workbench",
    "dataIncoming": "Data incoming",
    "applications": "Applications",
    "verify": "Verify",
    "digitalIDDashboard": "Get insights",
    "mySignatures": "My signatures",
    "externalConnectionManager": "External Connection Manager"
  },
  "dropdown": {
    "selectAll": "Select All",
    "noOption": "No options available",
    "noSuchOption": "There is no such option ..."
  },
  "common": {
    "start": "Start",
    "finish": "Finish",
    "firstName": "First name",
    "lastName": "Last name",
    "email": "Email address",
    "phoneNumber": "Phone number",
    "contactPerson": "Contact person",
    "welcome": "Welcome",
    "street": "Street",
    "city": "City",
    "country": "Country",
    "postCode": "ZIP code",
    "number": "Number",
    "next": "Next",
    "prev": "Back",
    "alreadyAccount": "Already have an account?",
    "login": "Log in",
    "loginNow": "Log in now",
    "register": "Register",
    "home": "Home",
    "submit": "Submit",
    "attention": "Attention",
    "desc": "Description",
    "website": "Website",
    "contact": "Contact",
    "details": "Details",
    "plan": "Plan",
    "listView": "List view",
    "gridView": "Grid view",
    "firstPage": "To the first page",
    "previousPage": "To the previous page",
    "nextPage": "To the next page",
    "lastPage": "To the last page",
    "download": "Download",
    "delete": "Delete",
    "edit": "Edit",
    "information": "Information",
    "organization": "Organization",
    "cancel": "Cancel",
    "save": "Save",
    "upload": "Upload",
    "link": "Link",
    "view": "View",
    "search": "Search",
    "searchTerm": "Search",
    "selectOption": "Select an option",
    "sortBy": "Sort by",
    "dataspace": "Dataspace",
    "select": "Select",
    "date": "Date",
    "title": "Title",
    "successful": "Successful",
    "unsuccessful": "Unsuccessful",
    "statuses": {
      "connected": "connected",
      "disconnected": "disconnected",
      "deploying": "deploying",
      "deployed": "deployed",
      "failed": "failed",
      "available": "available",
      "success": "success",
      "active": "active",
      "pending": "pending",
      "declined": "declined",
      "invited": "invited",
      "onboarding": "onboarding",
      "withdrawn": "withdrawn",
      "successful": "successful",
      "unsuccessful": "unsuccessful",
      "valid": "valid",
      "expired": "expired",
      "revoked": "revoked"
    },
    "andMore": "and {count} more",
    "confirm": "Confirm",
    "no": "No",
    "links": {
      "dihWiki": "https://docs.dih.telekom.com/en/home"
    },
    "formError": "Something is wrong.",
    "question": {
      "interested": "Interested?"
    },
    "moreInfo": "If you need more information:",
    "goToWebsite": "Go to our website",
    "provider": "Provider",
    "required": "Required",
    "errors": "Errors",
    "error": "Error"
  },
  "genericError": "Oh, something went wrong! Please try again.",
  "validation": {
    "fieldRequired": "{field} is required.",
    "fieldNumeric": "The field {field} may contain only numbers.",
    "fieldAlpha": "The field {field} may contain only letters, _ and -.",
    "fieldAlphanumeric": "The field {field} may contain only letters, numbers, _ and -.",
    "fieldNumericDash": "The field {field} may contain only numbers and -.",
    "fieldHouseNumber": "The field {field} may contain only numbers, letters, and the characters - and /.",
    "fieldPhoneNumber": "The field {field} may contain only numbers and -. It must start with + or 00.",
    "fieldAlphaDash": "The field {field} may contain only letters and -.",
    "exceptHTMLTagBrackets": "The field {field} must not contain the following characters: ^<>",
    "isMaxLength": "exceeds the maximum length of 10 characters.",
    "emailFormat": "Email address has wrong format",
    "fileSizeError": "The file size is bigger than {size} MB.",
    "csvFileSizeError": "This file is too big. You can upload file up to {size} MB.",
    "csvFileExtensionError": "Only files with the CSV extension are allowed.",
    "csvFileIsEmptyError": "This file is empty. You need to upload at least {size} KB file.",
    "csvFileFormatError": "The file does not match the expected format. Please use provided template and try again.",
    "uploadFails": "Upload failed",
    "fileExtensionError": "The file extension is not suitable",
    "fileIsMalwareInfectedError": "This file seems to contain malwares. For security reasons this file cannot be uploaded. Please check your file again",
    "customValidation": "The {field} has the wrong format.",
    "selectCountryFirst": "Please select Country name first",
    "validationPostCodeCaseDE": "The field has the wrong format. It must contain 5 digits, e.g., 53113",
    "validationAlphanumericCase": "The field may contain only alphanumeric characters, dashes, and underscores. The value must start with an alphanumeric character.",
    "validationNumericDashCase": "The field may contain only numbers and -.",
    "url": "Please enter a valid URL",
    "maxInvitationSize": "You can invite up to {size} participants at once",
    "password": {
      "passwordStrength": "Password strength",
      "weak": "Weak",
      "average": "Average",
      "strong": "Strong",
      "passwordValidationMoreChar": "{count} or more characters",
      "passwordUpperAndLowerChar": "Upper and lower case characters",
      "passwordAtLeastNumber": "At least one number",
      "passwordAtLeastSpecialChar": "At least one special character (except <>&\"`[])",
      "passwordDiffFromPrev": "Differs from your previous passwords"
    }
  },
  "footer": {
    "tagLine": "Let's power higher performance",
    "imprint": "Imprint",
    "imprintShort": "Imprint",
    "imprintLink": "https://dih.telekom.com/en/imprint",
    "contact": "Contact",
    "contactLink": "https://dih.telekom.com/en/contact",
    "termsAndConditions": "Terms & Conditions",
    "privacy": "Privacy policy",
    "privacyShort": "Privacy policy",
    "telekom": "T-Systems International GmbH. All rights reserved.",
    "telekomShort": "T-Systems International GmbH",
    "note": "Telekom Data Intelligence Hub - For Business Customers only",
    "brandedNote": "Powered by Telekom Data Intelligence Hub",
    "help": "Help",
    "cookieSettings": "Cookie settings",
    "cookieSettingsShort": "Cookies",
    "life": "Life is for sharing"
  },
  "onboarding": {
    "welcome": "Welcome to Data Intelligence Hub",
    "welcomeDIH": "Welcome to Data Intelligence Hub",
    "informationForOrganization": "Your organization information",
    "orgNameLabel": "Organization name",
    "orgUnitNameLabel": "Organization unit name",
    "emailAddressLabel": "Email address",
    "countryNameLabel": "Country",
    "bpnNumberLabel": "Business Partner Number (BPN)",
    "contactPersonInfo": "Contact person",
    "fillInfoBelow": "Please fill in the information below",
    "orgRegNameLabel": "Registered name of the organization/unit",
    "legalNameHint": "Please enter the legal name of your organization",
    "addressHeading": "Address of the organization headquarters",
    "PLZCode": "ZIP code",
    "verifyEmail": "Verify email",
    "verifyEmailMessage": "Thank you for joining Data Intelligence Hub – your organization is already registered with us. Please verify your email.",
    "taxId": "VAT number",
    "commerceRegisterNumber": "Commercial register number",
    "orgRepresentative": {
      "title": "Set up your organization representative's account",
      "orgContactPerson": "Organization contact person",
      "whatDefinesYou": "What defines you best?",
      "agreeText": "I confirm that I have read and will adhere to {1} of Data Intelligence Hub ",
      "confirmText": "I consent to my personal data being stored and processed according to <a href='#'>the Data Privacy Statement</a>"
    },
    "success": "Organization registration successful",
    "underReview": "Your organization is being verified.",
    "verified": "You have successfully verified your organization",
    "steps": {
      "counter": {
        "step": "Step",
        "of": "of"
      },
      "stepWizard": {
        "orgInfo": "Organization info",
        "selectConnection": "Select your connection",
        "selectPackage": "Select your plan",
        "checkout": "Check out"
      },
      "actionButton": {
        "back": "Back",
        "next": "Next",
        "order": "Order with obligation to pay",
        "checkout": "Check out",
        "selectMyPlan": "Select my plan"
      },
      "orgStep": {
        "fields": {
          "orgInfoLabel": "Organization details",
          "dataSpaceAttributes": "Additional attributes",
          "orgName": "Organization legal name",
          "departmentName": "Department name",
          "crnNumber": "Commercial register number",
          "taxId": "VAT number",
          "bpnNo": "Business Partner Number (BPN)",
          "addressInfo": "Billing address",
          "countryName": "Country",
          "plzCode": "ZIP code",
          "number": "Number",
          "city": "City",
          "street": "Street",
          "contactPersonLabel": "Contact person",
          "fullName": "Full name",
          "firstName": "First name",
          "firstNamePlaceholder": "Enter your first name",
          "lastName": "Last name",
          "lastNamePlaceholder": "Enter your last name",
          "contactPerson": "Contact person",
          "phoneNo": "Phone number",
          "emailAdd": "Email address",
          "dataPrivacyCheck": "I consent to my personal data being stored and processed according to the {dataprivacystatement} and the {supplementaryDataPrivacyConditions}",
          "termsAndConditionsCheck": "I confirm that I have read and will adhere to the {termsandcondition} of Data Intelligence Hub",
          "dataprivacystatement": "Data Privacy Statement",
          "supplementaryDataPrivacyConditions": "Supplementary Data Privacy Conditions",
          "termsandcondition": "Terms & Conditions",
          "agreeToEnterContract": "I agree to enter into a contract with T-Systems International GmbH based on the {serviceDescription}",
          "serviceDescription": "Service Description"
        }
      },
      "connection": {
        "sectionHeading": "Select your connection",
        "sectionSubHeading": "Please select your connection type for {dsName}",
        "textNote": "Only options that are compatible with Catena-X can be selected",
        "recommendedConfiguration": "Recommended configuration",
        "cloudAndDataPlan": "Cloud and data plan"
      },
      "packages": {
        "sectionHeading": "Select your plan",
        "sectionSubHeading": "Data plan variations",
        "dataPackage": "Data package",
        "outgoingTraffic": "Outgoing traffic",
        "storage": "Storage",
        "selectedDataPlan": "Select data plan",
        "dataPlanDetails": "Data plan details",
        "dataPlanLabelForVolume": "Plans with included volume",
        "dataPlanLabelFlexible": "Pay-as-you-go",
        "packageAvailable": "This package is available only with this plan",
        "chargesApply": "Charges apply if usage exceeds the volume limit",
        "additionalStorage": "Additional storage",
        "additionalTraffic": "Additional outgoing traffic"
      },
      "cloudProviders": {
        "cloudInfrastructure": "Select cloud provider",
        "cloudAvailable": "This package is available only on this cloud provider"
      },
      "checkout": {
        "sectionHeading": "Your connection package to {orgName}",
        "setupCost": "Setup costs",
        "monthlyCost": "Monthly costs",
        "payg": "Pay-as-you-go",
        "pipSheet": "Product information sheet",
        "totalCost": "Total cost",
        "totalPayable": "Total payable",
        "dataPrivacyCheck": "I agree to pay the charges associated with the services ordered above",
        "termsAndConditionsCheck": "I agree to the contract duration and the cancellation policies",
        "termsandcondition": "Terms & Conditions",
        "EOMAmountPolicy": "I accept that the total amount payable will be calculated at the end of the month",
        "generalAgreement": "I agree to enter into a contract with T-Systems International GmbH based on the above service description",
        "DPAAgreement": "I consent to the {dataprocessingagreement} (DPA)",
        "dataprocessingagreement": "Data Processing Agreement",
        "dataPackage": "Data package",
        "dataPlan": "Data plan",
        "outgoingTraffic": "Included outgoing traffic",
        "storagePrice": "Storage price",
        "perGigabyteForStorage": "/GB for storage",
        "perGigabyteForOutgoingTraffic": "/GB for outgoing traffic",
        "outgoingTrafficPrice": "Outgoing traffic price",
        "contractDuration": "Contract duration",
        "defaultContractDuration": "6 months",
        "price": "Price",
        "perGigabyte": "per GB",
        "monthlyCancelable": "monthly cancelable",
        "storage": "Included storage",
        "linksText": {
          "information": "Information sheet",
          "service": "Service Description"
        },
        "plusCharges": "{plus} charges for your consumption",
        "plus": "plus",
        "basedOnConsumption": "based on consumption"
      },
      "cancelModal": {
        "confirmQuestion": "Are you sure you want to cancel the selected connection?",
        "confirmYes": "Yes, cancel",
        "confirmNo": "No, continue"
      }
    },
    "statuses": {
      "complete": {
        "title": "You have already registered",
        "description": "The registration link cannot be used again.",
        "buttonText": "Go to home"
      },
      "error": {
        "title": "Something went wrong",
        "description": "Please restart the process",
        "buttonText": "Restart"
      },
      "expired": {
        "title": "Unfortunately your link has expired",
        "description": "Please restart the process from Catena-X portal.",
        "buttonText": "Back to Catena-X"
      },
      "success": {
        "title": "Thank you for registering",
        "description": "We are reviewing your organization's details. You will receive further information by email.",
        "buttonText": "Go to our website"
      },
      "invalid": {
        "title": "Unauthorized",
        "description": "You are not authorized to access this page.",
        "buttonText": "Go to our website"
      },
      "dih-success": {
        "title": "Thank you for your registration",
        "description": "We are reviewing your organization's details. You will receive further information by email.",
        "buttonText": "Go to our website"
      },
      "orgNotFound": {
        "title": "We couldn't find your organization",
        "descriptionLine1": "Sorry! Looks like your organization is not onboarded or you have not requested a subscription for T-Systems services yet.",
        "descriptionLine2": "Please request a subscription for T-Systems certified and tailor-made enablement services for Catena-X via the {marketplace}.",
        "marketplace": "marketplace",
        "descriptionLine3": "If you think this is a mistake please {contactOurSalesTeam} and we will be happy to support you.",
        "contactOurSalesTeam": "contact our sales team",
        "exploreTheKnowledgeBase": "Explore the Knowledge Base",
        "readOurBlog": "Read our Blog"
      }
    }
  },
  "dashboard": {
    "pageTitle": "Home",
    "pageSubtitle": "This is your control center for everything within Data Intelligence Hub."
  },
  "services": {
    "finishConfig": "Finish configuration",
    "open": "Open",
    "viewDetails": "View details",
    "configure": {
      "uploadCertification": "Please upload the certificate you received from the dataspace operator.",
      "dragAndDropCertificate": "Drag and drop your certificate file here.",
      "didntReceive": "Didn't receive one or don't know how to get one? Follow the steps here",
      "uploadSuccessMessage": "Certificate uploaded successfully"
    },
    "table": {
      "attribute": "Attribute",
      "value": "Value",
      "apiKey": "API key",
      "apiKeyValue": "API key value",
      "controlPlaneUrl": "Control plane URL",
      "idsURL": "DSP API Endpoint",
      "connectorUrl": "Connector URL",
      "dataManagementUrl": "Data management API Endpoint",
      "dataDashboard": "Data dashboard",
      "participantDid": "Participant DID ID"
    }
  },
  "dataspaces": {
    "dataspaces": "Dataspaces",
    "viewAllDataspaces": "View all dataspaces",
    "connectorTestDescription": "Application to test if your connect is running as expected and your connection to dataspace is active.",
    "configure": {
      "pageTitle": "Configure dataspace connection",
      "basicInfo": "Basic info",
      "certificate": "Certificate",
      "instructionCertrificate": "Please upload the certificate you received from the dataspace operator."
    },
    "connectorHealthCheck": {
      "title": "Connector Health Check",
      "copiedToClipboard": "Copied to clipboard",
      "description": "With the help of this application a self-test is performed to check if the connector you have selected is configured and working as expected. This will not change any settings or storage from the selected connector.",
      "connectorid": "Connector ID",
      "model": {
        "title": "Connector Health Check in progress...",
        "body": "You cannot leave while the health check is in progress. If you wish to leave, the health check will be terminated and will have to be restarted.",
        "toLeave": "No, I want to leave",
        "toFinish": "Finish the Health Check"
      },
      "banner": {
        "initiate": "The health check may take a few minutes to execute. You will be notified as soon as the result is available.",
        "success": "Your Connect self-test was successful! To view results of past tests, click on Results above.",
        "failed": "Sorry! Your Connect self-test was unsuccessful. Please contact {email} and we will be happy to support you.",
        "email": "dih_support{'@'}telekom.de"
      },
      "buttonState": {
        "initiate": "Initiate",
        "success": "Open Connect",
        "failed": "Contact Support",
        "back": "Back"
      }
    },
    "browse": {
      "browseDataspace": "Browse dataspaces",
      "availableDS": "Available dataspaces",
      "howToConnect": "How to connect",
      "getConnected": "Get Connected",
      "requestConnection": "Request Connection",
      "viewRequest": "View Request",
      "viewDetails": "View details",
      "highlightNote": "If you want to manage your membership with {selectedDataspaceName}, please contact our support:",
      "uncoverByInvitation": "Uncover by invitation",
      "dataspaceDetails": "Dataspace details",
      "participantOverview": "Participant overview",
      "services": "Services",
      "joinRequestSuccessTitle": "Your request to join the {dataspaceName} has been submitted",
      "joinRequestSuccessBody": "You will get further information once your request is processed",
      "uncover": {
        "title": "Show a dataspace by invitation",
        "subtitle": "Which dataspace are you looking for?",
        "fieldLabels": "Enter the details provided by the dataspace operator",
        "sendRequest": "Send request",
        "back": "Back",
        "dataspaceId": "Dataspace ID",
        "password": "Password"
      },
      "requestDetailsModal": {
        "withdraw": "Withdraw",
        "cancel": "Cancel",
        "update": "Update",
        "withdrawConfirmationHeading": "Withdraw Dataspace Join Request",
        "withdrawConfirmationBody": "Please reconfirm that you intend to withdraw your request to join the dataspace {dataspaceName}.",
        "notification": {
          "withdrawSuccessfulTitle": "Dataspace join request withdrawn",
          "withdrawSuccessfulBody": "Your request to join {dataspaceName} has been withdrawn.",
          "updateSuccessfulTitle": "Dataspace join request updated",
          "updateSuccessfulBody": "Your request to join {dataspaceName} has been updated."
        }
      }
    },
    "user": {
      "myMemberships": "My memberships",
      "enterDS": "Enter dataspace",
      "registeredDS": "Your dataspaces",
      "noMembership": {
        "title": "You have no membership yet",
        "description": "Browse dataspaces to join one",
        "actionButtonText": "Get Connected"
      }
    },
    "statuses": {
      "attentionRequired": "Attention required",
      "comingSoon": "Coming soon",
      "connected": "Connected",
      "disconnected": "Disconnected",
      "deploying": "Deploying",
      "deployed": "Deployed",
      "failed": "Failed",
      "available": "Available",
      "success": "Success"
    }
  },
  "verify": {
    "orgProfile": "Organization profile",
    "legalName": "Legal name",
    "address": "Address",
    "contact": "Contact",
    "vatID": "VAT number",
    "requestChange": "Request a change",
    "downloadVCMessage": "Successfully downloaded VC",
    "verifiableCredentials": {
      "getVerified": "Get verified",
      "credentials": "Credentials",
      "requestCredential": "Request credential",
      "whichTypeToSelect": "Which type should I select?",
      "noCredentialsMessage": "You have no credentials yet",
      "credentialInfoMessage": "Adding credentials will increase your trust level among participants of an ecosystem.",
      "credentialModalMessage": "Your credentials are the qualifications or pieces of information that establish a company's identity, expertise, or legitimacy.",
      "learnMore": "Learn more",
      "status": {
        "all": "All",
        "valid": "Valid",
        "expired": "Expired",
        "revoked": "Revoked"
      },
      "types": {
        "legalPerson": "Company identification",
        "serviceOffering": "Compliance and certification",
        "physicalResource": "Physical resources",
        "dataResource": "Data resources",
        "softwareResource": "Software resources",
        "membership": "Memberships"
      },
      "form": {
        "type": {
          "placeholder": "Select your credential type",
          "label": "Credential type",
          "error": "Credential type is required"
        },
        "types": {
          "legalPerson": "Legal Person",
          "serviceOffering": "Service Offering",
          "resource": "Resource",
          "physicalResource": "Physical Resource",
          "dataResource": "Data Resource",
          "softwareResource": "Software Resource"
        },
        "companyInformationSectionTitle": "Company information",
        "companyInformationSectionSubtitle": "Please enter your company information",
        "legalName": {
          "label": "Organization legal name",
          "helpText": "Please use the legally binding name of the organization"
        },
        "country": {
          "placeholder": "Select country",
          "label": "Country",
          "error": "Country is required"
        },
        "region": {
          "placeholder": "Select region",
          "label": "Region",
          "error": "Region is required"
        },
        "street": {
          "label": "Street and house number"
        },
        "city": {
          "label": "City"
        },
        "postalCode": {
          "label": "ZIP code"
        },
        "commercialIdentificationSectionTitle": "Commercial identification",
        "commercialIdentificationSectionSubtitle": "Enter at least one of the following unique IDs to proceed*",
        "CRN": {
          "label": "Commercial register number",
          "helpText": "Example: HRB 55933",
          "tooltipText": "The Commercial Register Number (CRN) is a unique identifier assigned to companies for their legal commercial registration. It is used to validate and verify the authenticity of a company's registration and financial transaction identifiers against trusted data sources."
        },
        "vatId": {
          "label": "VAT number",
          "helpText": "Example: DE 118 645 675",
          "tooltipText": "A VAT number (Value-Added Tax Identification number) is a unique identifier assigned to businesses for tax purposes."
        },
        "eori": {
          "label": "EORI",
          "helpText": "Example: DE 103 280 493 572",
          "tooltipText": "EORI (Economic Operator's Registration and Identification number) is a unique identifier assigned to economic operators engaged in international trade within the European Union (EU)."
        },
        "lei": {
          "label": "LEI",
          "helpText": "Example: 5299009D9BIL4D4UHT93",
          "tooltipText": "LEI (Legal Entity Identifier) is a unique identification code assigned to legal entities engaging in financial transactions involved in global financial markets."
        },
        "didSectionTitle": "Decentralized identifier (DID)",
        "didSubSectionTitle": "If you have no DID please keep this field empty, it will be generated automatically.",
        "did": {
          "label": "Credential subject (DID)",
          "helpText": "did:example123456789abcd",
          "tooltipText": "A DID is a unique identifier that can be resolved to a DID Document. DIDs are the fundamental building blocks of decentralized digital identity."
        },
        "request": "Request",
        "requesting": "Requesting...",
        "consentPersonalData": "I hereby agree and confirm that all my personal information and data (\"personal data\") stated above are accurate.",
        "consentProcessing": "I hereby consent and authorize Gaia-X to process any of my data (including any personal sensitive data)."
      },
      "vcRequestSuccess": "Successfully signed credential",
      "selectAllButton": "Select all",
      "deselectAllButton": "Deselect all",
      "revokeButton": "Revoke",
      "searchboxPlaceholder": "Enter at least 3 characters",
      "credentialTile": {
        "revokeButton": "Revoke",
        "issuedBy": "Issued by",
        "issuedOn": "Issued on",
        "expiresOn": "Expires on",
        "expiredOn": "Expired on",
        "revokedOn": "Revoked on",
        "viewDetailsButton": "View details"
      },
      "revokeModal": {
        "heading": "Revoke confirmation",
        "question": "Are you sure you want to revoke the item?",
        "note": "NOTE:",
        "message": "After revoking a credential it becomes invalid and can't be used anymore. To obtain a valid credential, you will have to request a new one.",
        "primaryButton": "Revoke",
        "secondaryButton": "Cancel"
      },
      "credentialsDetailsModal": {
        "heading": "Credential Details",
        "selfDescriptionCredential": "Self Description credentials",
        "type": "type",
        "id": "id",
        "issuer": "issuer",
        "issuanceDate": "issuanceDate",
        "expirationDate": "expirationDate",
        "credentialSubject": "credentialSubject",
        "bpn": "ctxsd:bpn",
        "participantName": "gx-participant:name",
        "participantNameLegalName": "gx-participant:legalName",
        "legalRegistrationNumber": "gx:participant:legalRegistrationNumber",
        "EUID": "gx:EUID",
        "leiCode": "gx:leiCode",
        "vatID": "gx:vatID",
        "registrationNumberType": "gx:registrationNumberType",
        "registrationNumberNumber": "gx:registrationNumberNumber",
        "headquarterAddress": "gx:participant:headquarterAddress",
        "addressCountryCode": "gx:addressCountryCode",
        "countrySubdivisionCode": "gx:countrySubdivisionCode",
        "locality": "gx:locality",
        "streetAddress": "gx:streetAddress",
        "legalAddress": "gx:participant:legalAddress",
        "termsAndConditions": "gx-participant:termsAndConditions",
        "serviceOfferingName": "gx:serviceOfferingName",
        "serviceOfferingDescription": "gx:serviceOfferingDescription",
        "providedBy": "gx:providedBy",
        "aggregationOf": "gx:aggregationOf",
        "aggregationOfId": "gx:id",
        "policy": "gx:policy",
        "serviceOfferingTermsAndConditions": "gx:termsAndConditions",
        "url": "gx:URL",
        "hash": "gx:hash",
        "dataAccountExport": "gx:dataAccountExport",
        "requestType": "gx:requestType",
        "accessType": "gx:accessType",
        "formatType": "gx:formatType",
        "proof": "Proof",
        "created": "created",
        "proofPurpose": "proofPurpose",
        "verificationMethod": "verificationMethod",
        "jws": "jws",
        "status": "Status",
        "primaryButton": "Download",
        "secondaryButton": "Cancel",
        "resourceName": "gx:name",
        "resourceDescription": "gx:description",
        "resourceCopyrightOwnedBy": "gx:copyrightOwnedBy",
        "resourceExposedThrough": "gx:exposedThrough",
        "resourceProducedBy": "gx:producedBy",
        "resourcePolicy": "gx:policy",
        "resourceLicense": "gx:license",
        "resourceContainsPII": "gx:containsPII",
        "resourceMaintainedBy": "gx:maintainedBy",
        "resourceOwnedBy": "gx:ownedBy",
        "resourceManufacturedBy": "gx:manufacturedBy",
        "resourceCountryCode": "Country code",
        "resourceLocality": "Locality",
        "resourcePostalCode": "Postal code",
        "resourceStreetAddress": "Street address"
      },
      "notificationText": "Sorry, we couldn't find any results matching your search criteria. Please refine your search and try again.",
      "bulkUpload": {
        "title": "Validate your partners",
        "helpText": "Validate your business partners all at once by filling out the provided template",
        "uploadFileTitle": "Upload file",
        "requestCredentials": "Request credentials",
        "uploadFormHelpHeading": "To validate multiple companies, please do the following:",
        "uploadFormHelpText1": "Download the template file from the link below",
        "uploadFormHelpText2": "Fill in the provided file with details of your business partners",
        "uploadFormHelpText3": "Drop the file in the upload area below",
        "downloadTemplateBtn": "Download template",
        "partnerValidationHelpText": "If you would like to learn more about partner validation, please",
        "partnerValidationHelpLink": "click here",
        "uploadFile": {
          "uploadFileHeading": "Select a CSV file to upload"
        },
        "vcProcessingTitle": "Processing of Records",
        "bulkVcContinueBtn": "Continue",
        "issuingProgressMessage": "Issuing of Credentials is in progress...",
        "doNotCloseWarning": "Please, don't close the form until the processing is finished",
        "bulkVcDownloadReportBtn": "Download report",
        "successMessage": "Successfully Completed!",
        "failedErrorMessage": "Errors in {result} entries",
        "processAbortTitle": "Processing",
        "processAbortDescription": "Are you sure you want to stop the processing?",
        "stopProcessingBtn": "Stop Processing"
      }
    },
    "serviceOfferingForm": {
      "generalInformationHeading": "General information",
      "labelSOName": "Service offering name",
      "labelProvidedBy": "Provided by",
      "labelSODescription": "Service offering description",
      "hintSOName": "Human readable name of the Service Offering",
      "hintProvidedBy": "Resolvable link in DID form to the participant self-description providing the service",
      "hintSODescription": "A list of policy expressed using DSL (e.g., Rego or ODRL, access control, throttling, usage, retention, …)",
      "resourcesHeading": "Resources",
      "labelSOResources": "Resource self-description",
      "hintSOResources": "Resolvable link in DID form of resource self-description, that is related to the service and can exist independently of it",
      "termsAndConditionsHeading": "Terms and Conditions",
      "labelUrlSO": "URL",
      "labelHashSO": "Hash",
      "termsAndConditionsTooltip": "Resolvable link to the Terms and Conditions applying to the Service Offering",
      "labelSOPolicy": "Policy",
      "policyTooltip": "Resolvable link to the Policy applying to the Service Offering",
      "personalDEHeading": "Personal data export",
      "personalDETooltip": "Enables customers of the service to export personal and non-personal data out of the service.",
      "labelRequestTypeSO": "Request type",
      "labelAccessTypeSO": "Access type",
      "placeholderRequestTypeSO": "Select Request type",
      "placeholderAccessTypeSO": "Select Access type",
      "labelFormatTypeSO": "Format type",
      "hintRequestTypeSO": "The mean to request data retrieval: API, email, webform, unregisteredLetter, registeredLetter, supportCenter",
      "hintAccessTypeSO": "Type of data support: digital, physical",
      "hintFormatTypeSO": "Type of Media Types (MIME types) as defined by",
      "consentTC1": "I hereby agree and confirm that all entered data stated above is accurate",
      "consentTC2": "I hereby consent and authorize T-Systems to process my data (including any personal sensitive data)",
      "consentTC3": "I hereby agree to the",
      "gaiaXTermsText": "Terms and Conditions of Gaia-X",
      "requestTypesList": {
        "api": "API",
        "email": "Email",
        "webform": "Webform",
        "unregisteredLetter": "Unregistered Letter",
        "registeredLetter": "Registered Letter",
        "supportCenter": "Support Center"
      },
      "requestTypesError": "Request type is required.",
      "accessTypesList": {
        "digital": "Digital",
        "physical": "Physical"
      },
      "accessTypesError": "Access type is required.",
      "helpSOName": "Please enter a human readable name of your service",
      "helpProvidedBy": "Decentralized Identifier of the Participant providing the service",
      "helpSOResources": "Decentralized Identifiers of the Resources that are exposed through this service",
      "helpUrlSO": "Please enter the link to your T&C document (e.g. https://service.com/terms)",
      "helpHashSO": "Please enter a sha256 hash of your T&C document",
      "helpPolicySO": "Please enter a policy expressed using a DSL (e.g., Rego or ODRL)",
      "helpRequestTypeSO": "Please select a request type from the dropdown list",
      "helpAccessTypeSO": "Please select an access type from the dropdown list",
      "helpFormatTypeSO": "Please enter the MIME type (e.g. application/json)"
    },
    "resourceForm": {
      "generalInformationHeading": "General information",
      "generalInformationSubHeading": "Please enter your resource general information",
      "labelResourceType": "Type of resource",
      "placeholderResourceType": "Select resource type",
      "errorResourceType": "Resource type is required",
      "labelResourceName": "Resource name",
      "labelResourceDescription": "Resource description",
      "hintResourceType": "Type of the resource being described",
      "hintResourceName": "Human readable name of the resource",
      "hintResourceDescription": "Human readable description of the resource",
      "physicalResource": "Physical resource",
      "softwareResource": "Software resource",
      "dataResource": "Data resource",
      "additionalInformationHeading": "Additional information",
      "additionalInformationSubHeading": "Please enter your resource additional information",
      "labelMaintainedBy": "Maintained by",
      "hintMaintainedBy": "Resolvable link to Gaia-X Credential of the participant maintaining the resource in operational condition and thus having physical access to it",
      "labelOwnedBy": "Owned by",
      "hintOwnedBy": "Resolvable link to Gaia-X Credential of the participant owning the resource",
      "labelManufacturedBy": "Manufactured by",
      "hintManufacturedBy": "Resolvable link to Gaia-X Credential of the participant manufacturing the resource",
      "labelCountry": "Country",
      "hintCountry": "Country the resource is located in",
      "labelCity": "City",
      "hintCity": "City / town the resource is located in",
      "labelPostCode": "Post code",
      "hintPostCode": "Post code the resource is located in",
      "labelStreet": "Street",
      "hintStreet": "Street and house number the resource is located in",
      "labelCopyrightOwnerBy": "Copyright owner by",
      "hintCopyrightOwnerBy": "A copyright owner is a person or organization that has the right to exploit the resource. Copyright owner does not necessarily refer to the author of the resource, who is a natural person and may differ from copyright owner. Expressed either as free text or as a resolvable link to Gaia-X Credential of the copyright owner.",
      "labelLicense": "License",
      "hintLicense": "URL of the license document or one of the licenses from the",
      "labelPolicy": "Policy",
      "hintPolicy": "A list of policy expressed using a DSL (e.g., Rego or ODRL) (access control, throttling, usage, retention, ...). If the policy constraint is omitted, it is assumed that the usage is allowed to all participants",
      "labelProducedBy": "Produced by",
      "hintProducedBy": "Resolvable link to Gaia-X Credential of the participant legally enabling the data usage.",
      "labelExposedThrough": "Exposed through",
      "hintExposedThrough": "Resolvable link to the data exchange component that exposed the data resource",
      "locationHeading": "Location details",
      "locationSubHeading": "Please enter your resource location details",
      "licenseTooltipText": "SPDX License List",
      "licenseTooltipLink": "https://spdx.org/licenses/",
      "consentPII": "I hereby confirm that the data resource does not contain Personal Identifiable Information (PII)."
    },
    "verifyLanding": {
      "heading": "Welcome to “Get verified”",
      "subHeading": "Increase the trust of your digital ecosystem!",
      "description1": "You want to verify and validate your business partners, or you want to show your own identification and verifications? Then you should request your first Verifiable Credentials - our",
      "description2": "Verifiable Credentials can contain the same information as your physical identifies have (e.g. ID card, driver's license) and even more attributes. The addition of technologies, such as digital signatures, makes verifiable credentials more tamper-evident and more trustworthy than their physical counterparts.",
      "description3": "T-Systems operates as one of the first Gaia-X Digital Clearing House Nodes and issues your digital proofs in accordance to the Gaia-X Trust Framework specifications. Moreover, we add our eIDAS conform digital signature from our Telekom Security partner.",
      "requestButton": "Request Digital.ID",
      "digitalID": "Digital.ID product.",
      "consultation" : {
        "heading": "Request your credentials or validate your partners",
        "placeholder": "Any previous experience with Verifiable Credentials?  Interested in your first credentials?"
      },
      "sendAnotherRequest": "Send another request",
      "lastRequest": "Your latest request was sent on"
    },
    "getInsights": {
      "tab1": "Reports",
      "tab2": "API Usage Limits",
      "tab3": "Technical Integration",
      "monthsByOptions": {
        "option1": "3 Months",
        "option2": "6 Months",
        "option3": "1 Month"
      },
      "table": {
        "heading2": "Reference period",
        "heading3": "Created At",
        "heading4": "Actions"
      },
      "successDownload":"Report(s) successfully Downloaded",
      "errorDownload":"Your file download for {filename} aborted with error, please try again.",
      "apiUsageLimit": {
        "requestNameTitle": "Requests name",
        "dailyTitle": "Daily API Requests",
        "monthlyTitle": "Monthly API Requests",
        "limitexhaustedWarning": "Less than 10% of requests left",
        "limitExtentionHeading": "Request limit extension",
        "limitExtentionPlaceholder": "Interested in extension of your API Usage Limits?",
        "supportBtn": "Support",
        "limitExtentionText": "About to reach the limit? Get in touch with us and let's sort it out!"
      },
      "addIntegration": "Add Integration",
      "integrationData": {
        "clientId": "Client ID",
        "createdAt": "Created At",
        "actions": "Actions"
      }
    },
    "addIntegration": {
      "generalInformationHeading": "General information",
      "addAnIntegration": "Add an integration",
      "clientId": "Client ID",
      "clientName": "Client Name",
      "clientSecret": "Client Secret",
      "tokenUrl": "Token URL",
      "pcCallbackUrl": "Participant Credential callback URL",
      "soCallbackUrl": "Service Offering Credential callback URL",
      "digitalIdAuthenticateInfo1": "How will the Digital.ID Validation authenticate against your system?",
      "digitalIdAuthenticateInfo2": "How will the Digital.ID Compliance authenticate against your system?",
      "seeResults": "Where should we send the results?",
      "requestSuccess": "added successfully",
      "callbackSuccess": "Callback added successfully",
      "step1Name": "Validation authentication details",
      "step2Name": "Compliance authentication details",
      "step3Name": "Callback information",
      "deleteSuccess": "deleted successfully",
      "downloadSuccess": "Downloaded Successfully",
      "editCallbackHeading": "Add callback",
      "deleteModal": {
        "heading": "Delete confirmation",
        "deleteContent": "Are you sure you want to delete the client?",
        "deleteNote": "After deleting a client it will disappear from the list and can't be used anymore. To restore it, you will have to recreate from the scratch."
      }
    },
    "mySignatures": {
      "certificatesOrdering": {
        "heading": "Welcome to “My Certificates”",
        "subHeading": "Would you like to request a digital certificate that allows you to sign your claims?",
        "description1": "With our Digital.ID product, you can easily request your first certificate and begin enjoying the benefits of secure digital collaborations.",
        "description2": "Verifiable Credentials can contain the same information as your physical identifiers, such as ID cards and driver's licenses, and even more. During the issuing process, you'll confirm the accuracy of your information by signing it with your own trustworthy certificate.",
        "description3": "You can safely request and maintain your digital certificates for all your signing needs. We exclusively partner with certified digital certificate providers, ensuring that your digital certificates are both secure and trustworthy.",
        "requestButton" : "Request Certificate",
        "consultation" : {
          "heading": "Request certificate",
          "placeholder": "Please, enter necessary details about your request"
        }
      },
      "certificateOverview": {
        "heading": "My Signatures",
        "requestCertificateCTA": "Request Certificate",
        "placeholderCertificateType": "Certificate type",
        "placeholderCertificateStatus": "Certificate status",
        "EVSSL": "EV SSL",
        "EIDAS": "EIDAS",
        "headingEVSSL": "EV SSL Certificates",
        "headingEIDAS": "EIDAS Certificates",
        "certificateDetailsHeading": "Certificate Details",
        "certificateName": "Name",
        "certificateId": "ID",
        "certificateType": "Type",
        "provider": "Provider",
        "organizationId": "Organization ID",
        "UUID": "UUID",
        "certificate": "Certificate",
        "createdAt": "Created On",
        "updatedAt": "Updated On",
        "certificateExpiry": "Expires On",
        "certificateVerificationMethod": "Verification Method",
        "certificateDidJson": "DID JSON",
        "status": "Status",
        "certificateDownloadSuccess": "Certificate downloaded successfully"
      }
    }
  },
  "build": {
    "ownedDataspaces": "My dataspaces",
    "checkConsent": "I consent to my contact data being provided to a T-Systems sales team and to being contacted.",
    "welcomeOwnedDataspace": "Welcome to “Owned dataspaces”",
    "resendInvitation": "Resend Invitation",
    "resendInvitationInfo": "You can send the notification as soon as it has been 24 hours since the last invitation mail.",
    "requestSuccessTitle": "Successful request!",
    "requestSuccessBody": "Your request has been sent... you’ll receive an answer in a few working days",
    "inviteParticipant": {
      "buttonLabel": "Invite Participant",
      "successNotificationTitle": "Invitation successful!",
      "successNotificationContent": "Your invitation has been sent successfully",
      "alreadySentNotificationTitle": "An invitation has already been sent to this email address.",
      "alreadySentNotificationContent": "Please wait for 24 hours before sending the invitation again.",
      "resendInviteNotificationTitle": "Re-invitation successful!",
      "resendInviteNotificationContent": "Your re-invitation has been sent successfully",
      "modal": {
        "title": "Invite Participant",
        "informationTitle": "Information",
        "informationText": "The invited organization can exchange data with other participants and publish new data assets in the dataspace. Additionally, the organization can consume applications & services that are part of your dataspace. For more information please checkout the following:",
        "formTitle": "Please enter the details of the new participant",
        "organizationName": "Organization name",
        "proceed": "Proceed",
        "cancel": "Cancel"
      },
      "deleteInvitation": {
        "modal": {
          "title": "Delete Invitation",
          "informationText": "Please reconfirm that you intend to delete this invitation to the dataspace. This action cannot be undone.",
          "successNotificationTitle": "Delete Successful",
          "successNotificationBody": "Invitation Deleted Successfully",
          "cancel": "Cancel",
          "delete": "Delete"
        }
      }
    },
    "editParticipant": {
      "modal": {
        "title": "Edit participant",
        "cancel": "Cancel",
        "save": "Save",
        "roleSection": {
          "name": "Role",
          "helpText": "Which role should I select?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "operatorLabel": "Dataspace operator",
          "operatorTooltip": "Participant who can manage operator toolkit and onboard applications",
          "operatorCheckboxTooltip": "You are the owner of the dataspace. Therefore, you cannot deselect Operator.",
          "participantLabel": "Dataspace participant",
          "participantTooltip": "Participant who can act as a data provider and a data consumer",
          "participantCheckboxTooltip": "Dataspace participant can't be disabled. Thats the default status of a user in a dataspace."
        },
        "visibilitySection": {
          "name": "Visibility",
          "helpText": "Which visibility should I select?",
          "helpUrl": "https://docs.dih.telekom.com/en/space/living-lab/manage-your-livinglab",
          "anonymousLabel": "Anonymous",
          "anonymousLabelTitle": "Anonymous participant",
          "anonymousTooltip": "Participant who is hidden from other participants and is not able to view other participants"
        }
      },
      "updateSuccessfulTitle": "Update Successful!",
      "updateSuccessfulBody": "New role & visibility have been adopted"
    },
    "applicationManagement": {
      "buttonLabel": "Register application",
      "registerForm": {
        "pageTitle": "Application Registration",
        "step1Name": "Application details",
        "step2Name": "Application access",
        "displayName": "Display name",
        "providedBy": "Provider",
        "providedByPlaceholder": "Organization name",
        "description": "Description",
        "descriptionPlaceholder": "Description text",
        "link": "Link",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "I hereby consent that I am fully responsible and liable for the application that I am registering and that it is not malware of any kind. Furthermore, I have ensured that there is no license violation. I understand that I am fully responsible for the access management of the application and that T-Systems International GmbH bears no responsibility in this regard. T-System International GmbH is not responsible or liable for the application and its possible consequences in any possible way."
      },
      "deleteApplication": {
        "modal": {
          "title": "Delete Application",
          "informationText1": "Please reconfirm that you are sure you want to delete this application link from your dataspace. This application will be not available for any participant within your dataspace.",
          "informationText2": "Please note, the actual application will remain unaffected!",
          "successNotificationTitle": "Delete Successful",
          "successNotificationBody": "Application Deleted Successfully",
          "cancel": "Cancel",
          "delete": "Delete"
        }
      },
      "editForm": {
        "pageTitle": "Edit Application",
        "step1Name": "Application details",
        "step2Name": "Application access",
        "displayName": "Display name",
        "providedBy": "Provider",
        "providedByPlaceholder": "Organization name",
        "description": "Description",
        "descriptionPlaceholder": "Description text",
        "link": "Link",
        "successNotificationTitle": "Edit Successful",
        "successNotificationBody": "Application Edited Successfully",
        "linkPlaceholder": "https://www.application-link-example.org/",
        "agreementCheckboxLabel": "I hereby consent that I am fully responsible and liable for the application that I am registering and that it is not malware of any kind. Furthermore, I have ensured that there is no license violation. I understand that I am fully responsible for the access management of the application and that T-Systems International GmbH bears no responsibility in this regard. T-System International GmbH is not responsible or liable for the application and its possible consequences in any possible way.",
        "cancel": "Cancel",
        "save": "Save Changes"
      }
    },
    "invite": {
      "inviteLabel": "Invite Participant"
    },
    "allocateConnectors": {
      "pageTitle": "Connector Allocation",
      "allocateConnectorLabel": "Allocate Connectors",
      "topUpConnectorsButtonLabel": "Top up connectors",
      "topUpConnectorTitle": "Your request was successfully submitted.",
      "topUpConnectorBody": "Our support team will contact you as soon as possible to discuss further details.",
      "tooltipMessage": "Please contact DIH support (dih_support{'@'}telekom.de) to allocate new connectors to existing participants or to invite a new participant to the dataspace",
      "allocationForm": {
        "step1Name": "Organization",
        "step2Name": "Connectors",
        "organizationNameDescription": "Select the company for which new connectors should be allocated to",
        "organizationNameLabel": "Organization name",
        "organizationPlaceholder": "Choose Organization",
        "inviteParticipantDescription": "Or click on the invite participant button to invite a  new company to collaborate with, on the dataspace",
        "inviteParticipantButton": "Invite participant",
        "numberOfConnectorsDescription": "Choose number of connections",
        "numberOfConnectorsLabel": "Number of connectors",
        "numberOfConnectorsPlaceholder": "Count",
        "progressBarDescription": "Connector quota",
        "progressBarInfo": "{numberOfConnectors} connectors available",
        "allocateButtonLabel": "Allocate",
        "cancelButtonLabel": "Cancel",
        "noConnectorRemaining": "All connectors are used. Please click on \"Top up connectors\" to discuss the extension of the connectors with our team."
      },
      "successRequestTest": "Successful Request",
      "successRequestBody": "Connector Allocation has been successfully initiated",
      "decisionModal": {
        "title": "You have been invited to join {dataspaceName}",
        "accept": "Accept",
        "decline": "Decline",
        "acceptSuccessNotificationTitle": "Acceptance successful!",
        "acceptSuccessNotificationBody": "You have successfully accepted the invitation to join {dataspaceName}.",
        "declineSuccessNotificationTitle": "Invitation declined!",
        "declineSuccessNotificationBody": "You have declined the invitation to join {dataspaceName}."
      }
    },
    "request": {
      "requestTitle": "Take the next step on the data sharing journey and create your own dataspace!",
      "requestDescription1": "You want to not just share your own data, but also enable your business partners to share data with each other? Then you should consider creating a dataspace, which is a trusted community for sharing or monetizing data.",
      "requestDescription2": "T-Systems will set up and operate your dataspace on our reliable infrastructure.",
      "requestDescription3": "We also offer low-threshold test setups for your first dataspace experiences („LivingLab“).",
      "request": "Request",
      "sendAnotherRequest": "Send another request",
      "lastRequest": "Your last request was sent on",
      "back": "Back",
      "sendRequest": "Send request",
      "requestedTextPlaceholder": "Are you looking for something specific or general consultation? Any previous experience with dataspaces? Interested in a test setup?"
    },
    "create": {
      "createTitle": "Create your own dataspace",
      "createDesc": "You can send a request to our expert team and we will get back to you.",
      "tellUsMore": "Can you tell us more about your request?"
    },
    "details": {
      "approve": "Approve",
      "decline": "Decline",
      "approval": "Approval",
      "requesterNotes": "Requester Notes",
      "adminNotes": "Admin Notes",
      "decisionTakenTooltip": "Acceptance or decline already happened. The provision of the connectors is currently being prepared. You will be informed as soon as these have been completed.",
      "decisionInputPlaceholder": "Notes for Approval or Denial ...",
      "activeParticipants": "Active participants",
      "incomingRequests": "Incoming requests",
      "invitations": "Invitations",
      "manage": "Manage",
      "enter": "Enter",
      "providedBy": "Provider",
      "registered": "Registered",
      "operatorToolkit": "Operator toolkit",
      "dataspaceDetails": "Dataspace details",
      "contactDetails": "Contact details",
      "visibilityTitle": "Hide your dataspace from browse dataspace listing",
      "manageParticipants": "Manage participants",
      "applicationManagement": "Application management",
      "noApplications": {
        "title": "You have no applications yet",
        "actionButtonText": "Register application"
      },
      "contactUs": "If you want to update any information please contact us directly",
      "noData": "You don't have any data yet",
      "basic": "Basic",
      "companyName": "Company name",
      "participationMode": "Participation mode",
      "connectorName": "Connector name",
      "contact": "Contact",
      "address": "Address",
      "street": "Street",
      "number": "Number",
      "postalCode": "Post code",
      "city": "City",
      "country": "Country",
      "phoneNo": "Phone number",
      "contactPerson": "Contact person",
      "email": "Email",
      "dateOfJoining": "Date of joining",
      "dateOfInvitation": "Date of invitation",
      "dateOfRequest": "Date of request",
      "url": "URL",
      "status": "Status",
      "name": "Name",
      "description": "Description",
      "website": "Website",
      "dataspaceId": "Dataspace ID",
      "password": "Password",
      "digitalId": "This dataspace is Digital.ID enabled",
      "disableDigitalId": "Digital.ID is not enabled for this dataspace",
      "company": "Company | Companies",
      "connector": "Connector | Connectors",
      "application": "Application | Applications",
      "dataoffer": "Data offer | Data offers",
      "visibility": "Your dataspace is now {access}",
      "discoverable": "Discoverable",
      "private": "Private",
      "deleteParticipant": {
        "modal": {
          "title": "Delete Participant",
          "informationText": "The connector belonging to the participant will be undeployed upon confirmation. This action cannot be undone.",
          "successNotificationTitle": "Delete Requested",
          "successNotificationBody": "Deletion of a participant is requested",
          "cancel": "Cancel",
          "delete": "Delete"
        }
      },
      "services": {
        "applicationOrchestration": "Application orchestration",
        "applicationOrchestrationDesc": "Deploy and manage your business applications",
        "identityAccessManagement": "Identity and access management",
        "identityAccessManagementDesc": "Manage your users and their access to resources",
        "continuousDelivery": "Continuous delivery",
        "continuousDeliveryDesc": "Embrace the deployment and operations best practices",
        "secretCredentialManagement": "Secret and credential management",
        "secretCredentialManagementDesc": "Secure, store, and control access to tokens, passwords, and certificates",
        "monitoring": "Monitoring",
        "monitoringDesc": "Query, visualize, get alerted about, and understand your metrics and KPIs",
        "connectorTest": "Connector health check",
        "connectorTestDesc": "Service to run a health check for connectors.",
        "catenaXSandbox": "Catena-X Sandbox",
        "catenaXSandboxDesc": "Start your Catena-X sandbox within your LivingLab to develop and test your new business applications in the Catena-X environment."
      }
    },
    "connectorHealthCheck": {
      "title": "Connector Health Check",
      "viewResults": "View results",
      "close": "Close",
      "description": "Choose one or more connectors to execute a health check on. The standard dataspace operations will not be affected by the health check.",
      "connectors": "connectors",
      "placeholder": "Choose connectors",
      "searchInputLabel": "Search connectors...",
      "banner": "The health check may take a few minutes to execute. You will be notified as soon as the result is available.",
      "action": "Initiate",
      "history": {
        "connectorName": "Connector name",
        "initiated": "Initiated",
        "completed": "Completed",
        "result": "Result",
        "successMessage": "{connectorName} is working as expected",
        "unsuccessfulMessage": "{connectorName} is not working as expected. Please contact "
      },
      "successNotificationTitle": "Connector health check initiated.",
      "successNotificationContent": "You will be notified when the result is available.",
      "noTest": "You have no tests yet",
      "initiateTest": "Initiate Test"
    },
    "specifications": {
      "table": {
        "attribute": "Attribute",
        "value": "Value",
        "cloudProvider": "Cloud provider",
        "contractStart": "Contract start",
        "contractDuration": "Contract duration",
        "cpu": "CPU",
        "ram": "RAM",
        "storage": "Storage",
        "maximumConnectorsBookable": "Maximum connectors bookable",
        "subscribedConnectors": "Subscribed connectors"
      },
      "dataspaceSpecifications": "Dataspace specifications",
      "livinglabCore": "LivingLab core",
      "livinglabAppLayer": "LivingLab application layer"
    }
  },
  "profile": {
    "profile": "Profile",
    "userProfile": "User profile",
    "personalInfo": "Personal information",
    "personalDetails": "Personal details and settings",
    "changeDetailsMessage": "If you wish to change any details, please contact",
    "changeDetailsLinkText": "support",
    "memberships": "Memberships",
    "security": "Security",
    "setUp2FA": "Set up 2FA",
    "accountInformation": "Account information",
    "deleteAccount": "Delete user account",
    "deleteAccountDescription": "With this action, all data and data files associated with your personal account will be deleted.",
    "accountSecurity": "Account security",
    "yourInfomration": "Your information",
    "fullName": "Full name",
    "contact": "Contact",
    "language": "Language",
    "changePassword": "Change password",
    "please": "Please",
    "contactSupport": "contact support",
    "toDownload": "to download this information",
    "toDelete": "to delete your profile",
    "roles": "Roles",
    "memberSince": "Member since",
    "orgLegalName": "Organization legal name",
    "bpnNumber": "Business Partner Number (BPN)",
    "commRegNumber": "Commercial register number",
    "vatIdNumber": "VAT number",
    "hqAddress": "Headquarter address",
    "legalIdentifier": "Legal identifier",
    "additionalAttributes": "Additional attributes"
  },
  "plansBenefits": {
    "communicationIntegrity": "Communication integrity",
    "protectedConnection": "Protected connection",
    "mutualAuth": "Mutual authentication",
    "stateArtOfCrypto": "State-of-the-art cryptography",
    "remoteAttestation": "Remote attestation",
    "platforInt": "Platform integrity",
    "configureAndApp": "Configuration & app integration",
    "dataUsageControl": "Data usage control",
    "definitionOfUsagePolicy": "Definition of usage policy",
    "from": "from",
    "month": "month | months",
    "connect": "Connect"
  },
  "cookieSettings": {
    "collapsedTitle": "Privacy settings",
    "collapsedText": "This website uses cookies and similar technologies. These are small text files that are stored and read on your computer. By clicking on '{0}', you accept the processing, the creation of individual user profiles across websites and partners, and the transfer of your data to third parties, some of whom process your data in countries outside the European Union (GDPR Art. 49). Details can be found in section'2 of the {1}. The data is used for analysis, retargeting and for playing out personalized content and advertising on Telekom sites and third-party sites. Further information, including information on data processing by third-party providers and the possibility of revocation, can be found in the settings and in our privacy information. Here you can {2} only with the necessary tools.",
    "extendedTitle": "Privacy settings",
    "extendedText": "In order to provide you with an optimal website experience, we use cookies. These include cookies for the operation and optimization of the site as well as for services such as text or video chat and for advertising based on your online usage behavior. This allows us, for example, to detect if you visit our pages repeatedly from the same device. We would like to give you the choice which cookies you allow:",
    "onlyRequired": "Only required",
    "policyLinkHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkCookieHref": "https://dih.telekom.com/en/privacy-policy",
    "policyLinkTextShort": "privacy information",
    "policyLinkText": "Privacy information",
    "rejectLinkText": "continue",
    "acceptButton": "Accept all",
    "saveButton": "Save settings",
    "settingsButton": "Change settings",
    "showMore": "Show more",
    "showLess": "Show less",
    "categoryTitleEssential": "Required cookies",
    "categoryTitleAnalytics": "Analytical cookies",
    "categoryTitleMarketing": "Marketing cookies",
    "categoryTitleThirdParty": "Services by other companies (independent third party providers)",
    "categoryTextEssential": "These cookies are required to enable you to navigate through the websites and use key functions. They support basic functions, such as order processing in the online shop and access to secured areas of the web page. They also serve the purpose of performing an anonymous analysis of user patterns, which we use to continuously develop and improve our web pages for you.",
    "categoryTextAnalytics": "These cookies help us to improve our understanding of user behavior. Analysis cookies allow for the compilation of usage and identification data by the original provider or third party providers into pseudonymous usage profiles. We use analysis cookies e.g. to determine the number of individual visitors to a web page or a service, to collect statistical data on the performance of our products and to analyze the visitors' usage patterns and visitor interactions on the basis of anonymous and pseudonymous information. This information cannot be traced back to a person.",
    "categoryTextMarketing": "These cookies and similar technologies are used to enable the display of personalized and therefore relevant marketing content. Marketing cookies are used to display interesting advertising content and to measure the effectiveness of our campaigns. This happens not only on Telekom websites, but also on other advertising partner sites (third-party providers). This is also called retargeting. It is used to create pseudonymous content or ad profiles, to place relevant advertising on other websites, and to derive insights into target groups that have viewed the ads and content. This information cannot be traced back to a person. Marketing and retargeting tools assist us in serving you advertising content that is potentially relevant for you. By suppressing marketing cookies, you will still see the same amount of advertising, but it may be less relevant to you.",
    "categoryTextThirdParty": "Telekom's websites include links to third-party service providers, who provide their services under their own responsibility. When you visit Telekom's web pages, cookies or similar technologies record data and send it to third parties, in part for Telekom's own purposes. The scope, purpose, and legal basis on which further processing is carried out for the third party's own purposes can be found in the third party's data privacy information. Information on the independent third-party providers can be found in the data privacy information."
  },
  "consultation": {
    "createDescription": "You can send a request to our expert team and we will get back to you.",
    "tellUsMore": "Can you tell us more about your request?",
    "checkConsent": "I consent to my contact data being provided to a T-Systems sales team and to being contacted.",
    "back": "Back",
    "sendRequest": "Send request",
    "successMsgtitle": "Successful request!",
    "successMsgBody": "Your request has been sent... you'll receive an answer in a few working days"
  },
  "notifications": {
    "notifications": "Notifications",
    "inbox": "Inbox",
    "clearAll": "Clear all",
    "blankState": {
      "heading": "No notifications yet",
      "description": "When you get notifications, they'll show up here",
      "actionButtonText": "Refresh"
    },
    "actionMessage": {
      "seeRequest": "See the Request",
      "here": "here",
      "checkResult": "Check the result "
    },
    "goToMyAccount": "Please view your new role(s) information under {myAccountLink}."
  },
  "statusTile": {
    "noMembership": {
      "title": "You have no membership yet",
      "subTitle": "Browse dataspaces to join one",
      "getConnected": "Get connected"
    }
  },
  "settings": {
    "organization": {
      "pageTitle": "My organization",
      "orgDetails": "Organization details",
      "teamMembers": "Team members"
    },
    "teamMembers": {
      "title": "User management",
      "description": "This section helps in managing the users of your organization"
    },
    "inviteUser": {
      "inviteUsers": "Invite users",
      "newEmailAddress": "New team members e-mail address",
      "chooseRole": "Choose role",
      "whichRoleToSelect": "Which role should I select?",
      "roleNote": "Selected role will be given to all users being invited",
      "roleError": "Please select at least 1 role from the available options",
      "roleErrorShort": "Please select at least 1 role",
      "cancel": "Cancel",
      "sendInvite": "Send invite",
      "fullName": "Full name",
      "status": "Status",
      "role": "Role",
      "memberSince": "Member since"
    },
    "inviteSuccess": {
      "title": "Summary of the invitation",
      "totalInvitation": "{count} invite was sent | {count} invites were sent",
      "description": "{count} invite was not sent because the email address already exists among the users of your organization | {count} invites were not sent because the email addresses already exist among the users of your organization",
      "actionContinue": "Continue"
    },
    "roleChange": {
      "areYouSure": "Are you sure you want to make these role changes?",
      "yeahSure": "Yes, I'm sure"
    }
  },
  "completeInviteProfile": {
    "title": "Complete your account",
    "description": "You have been invited to Telekom Data Intelligence Hub. To complete your account please fill in the required information.",
    "firstName": "First name",
    "firstNamePlaceholder": "Enter your first name",
    "lastName": "Last name",
    "lastNamePlaceholder": "Enter your last name",
    "email": "Email",
    "emailPlaceholder": "Enter email address",
    "organisation": "Organization",
    "organisationPlaceholder": "Enter your organization",
    "enterPassword": "Enter password",
    "dataPrivacyCheck": "I approve that my personal data is stored and processed according to the purposes indicated in the {dataAndPrivacyStatement}.* ",
    "termsAndConditionsCheck": "I confirm that I have read and will adhere to the {termsandcondition} of Data Intelligence Hub.",
    "termsandcondition": "Terms & Conditions",
    "dataAndPrivacyStatement": "Data Privacy Statement",
    "cancel": "Cancel",
    "submit": "Submit",
    "success": {
      "title": "Your account has been completed",
      "description": "You will be directed to the login page",
      "actionContinue": "Continue"
    }
  },
  "workbench": {
    "incomingDataAgreements": {
      "title": "Incoming data agreements",
      "addedOn": "Added on",
      "expiresOn": "Expires on",
      "providedBy": "Provided by",
      "dateAddedOn": "Date added on",
      "noFilesAttached": "No files are currently attached to this contract agreement",
      "noAgreementsMessage": "You have no incoming data agreements",
      "receivingData": "Receiving data",
      "dataReceived": "Downloaded",
      "error": "Error occurred",
      "transferInitiated": "Transfer initiated",
      "transferCompleted": "Transfer completed!",
      "view": "View",
      "successDownload": "Data asset successfully received",
      "errorDownload": "Your file download for {filename} aborted with error, please try again",
      "viewAggreement": "View Agreement",
      "agreeText": "By downloading the files you agree to comply with the {contractTerms} of this agreement",
      "contractTerms": "Contract Terms",
      "includedFile": "Included file",
      "createdOn": "Created on",
      "fileType": "File type",
      "downloadError": "Failed to fetch incoming data agreements",
      "downloadSuccess": "Data asset successfully received"
    },
    "contractDetails": {
      "termsAndPolicies": "Terms & Policies",
      "offerBy": "Offer by",
      "addedOn": "Added on",
      "expiresOn": "Expires on",
      "product": "Product",
      "totalFiles": "Total Files",
      "description": "Description",
      "contractID": "Contract ID",
      "contractTerms": "Contract Terms",
      "files": "Files",
      "parameters": "Parameters",
      "referencePeriod": "Reference Period",
      "location": "Location",
      "additionalLinks": "Additional Links",
      "dihMainPage": "DIH Main Page",
      "download": "Download",
      "search": "Search",
      "title": "Title",
      "createdOn": "Created on",
      "kpiName": "KPI Name",
      "fileType": "File Type",
      "schema": "Schema",
      "dashboard": "Dashboard",
      "selectAll": "Select All",
      "deselectAll": "Deselect All",
      "transfer": "Transfer",
      "downloadSelected": "Download Selected",
      "noTermsPolicy": "At this time, the Data Provider has not specified any rules for the use of data within this contract.",
      "youCan": "You can",
      "youCant": "You can't",
      "youMust": "You must",
      "respectContent": "Respect the content of the Licence Agreement",
      "licenceAgreement": "Licence Agreement",
      "noAttachment": "No attachments yet",
      "termsandcondition": "Terms & Conditions",
      "resellData": "Resell the data",
      "youCanUseDataCommercial": "Use the data for commercial purposes",
      "youCanProcessData": "Process the data on the infrastructure outside of European Union",
      "youCanEmpty": "At this time, the Data Provider hasn't specified any permissions for the use of data within this contract",
      "youCantEmpty": "At this time, the Data Provider hasn't specified any prohibitions for the use of data within this contract",
      "youMustEmpty": "At this time, the Data Provider hasn't specified any obligations for the use of data within this contract"
    }
  },
  "messages": {
    "noDataAvailable": "No data available",
    "noMatchFound": "No match found",
    "maximumFileSize": "Maximum file size",
    "suitableFileFormats": "Suitable file formats",
    "loading": "Loading...",
    "dragDropText": "or drag and drop it here"
  },
  "pageNotFound": {
    "title": "Oops! Looks like the page you are looking for doesn't exist yet.",
    "subtitle": "Check out our other resources in the meantime, which might be helpful for you!"
  },
  "unauthorized": {
    "pageNotAccessible": "Ooops! Looks like the page you are looking for is not accessible with your permissions.",
    "reachOutToAdministrator": "Please reach out to your Administrator to further inquire about your access.",
    "checkOtherResources": "In the meanwhile, check out our other resources."
  },
  "helpResources": {
    "needHelp": "Need help in finding the right plan, more info, technical questions?",
    "FAQ": "Frequently Asked Questions",
    "FAQDescription": "Find more common answers from billing to privacy",
    "browse": "Browse",
    "demos": "Demos",
    "demosDescription": "Find use cases and demos relevant to your Industrial sector",
    "glossary": "Glossary",
    "glossaryDescription": "Find the Terms and Definitions related to Data Intelligence Hub, IDSA, Catena-X, Gaia-X and more."
  },
  "feedbackCard": {
    "giveUsFeedback": "Give us feedback on what we are missing?",
    "contactUs": "Contact us"
  },
  "requestDataspaceJoin": {
    "title": "Request connection to {dsName}",
    "basic": "Basic",
    "orgName": "Organization Name:",
    "contact": "Contact",
    "address": "Address:",
    "name": "Name:",
    "email": "Email:",
    "joinReasonTitle": "Please indicate why you want to join the dataspace?",
    "joinConsent": "I consent to share the above details with the dataspace owner",
    "sendRequest": "Send Request"
  },
  "applications": {
    "myApplication": "My Applications"
  },
  "externalConnection": {
    "title": "External Connection Manager",
    "noConnectionConfigureTitle": {
      "title": "You have no connection configured yet",
      "newConnection": "New Connection"
    },
    "request": {
      "connectionDetails": "Connection details",
      "preferConnection": "Select prefer mode of server connection",
      "selectConnection": "Select Connection",
      "ageeTerms": "I hereby agree and confirm that all my personal information and data stated above are accurate",
      "grantConsent": "I grant consent to Telekom Data Intelligence Hub for the processing and storage of this data to facilitate asset transfers through the provided connection",
      "back": "Back",
      "addConnection": "Add Connection",
      "save": "Save",
      "verifyConnectivity": "Verify Connectivity",
      "checkingConnection": "Checking connection",
      "connectionVerified": "Connection verified",
      "error": "Error",
      "requestSuccessTitle": "Successfully added!",
      "requestSuccessText": "You have successfully added your connection details.",
      "requestUpdatedTitle": "Successfully updated!",
      "requestUpdatedText": "You have successfully updated your connection details.",
      "maxConnectionReached": "You have reached the maximum number of free connections."
    },
    "delete": {
      "title": "Connection deletion",
      "description": "​Are you sure you want to delete this connection?",
      "cancel": "Cancel",
      "delete": "Delete",
      "requestDeleteSuccessTitle": "Successfully deleted!",
      "requestDeleteSuccessText": "You have successfully deleted your connection."
    },
    "transfer": {
      "title": "Connection Manager",
      "connectionDetails": "Connection details",
      "type": "Type",
      "hostName": "Host name",
      "rootPath": "Root path",
      "authType": "Authentication type",
      "username": "Username",
      "verificationStatus": "Verification status",
      "disclaimer": "Disclaimer",
      "disclaimerText": "By confirming your selection, you recognize that the chosen connection is beyond the control of Telekom Data Intelligence Hub. Consequently, you assume responsibility for any issues in data transfer arising from external factors, including but not limited to insufficient storage, network connectivity, access and authentication restrictions, and other relevant considerations.",
      "confirmSelection": "Confirm Selection",
      "newConnection": "New Connection",
      "noConnectionModalTitle": "You have no connections yet",
      "noConnectionModalDesc": "You can create a new connection by clicking on the button below.",
      "noConnectionAddNewButton": "Add New Connection",
      "successModalTitle": "The transfer will take longer than usual",
      "successModalDesc": "We will send a notification once the file transfer is complete.",
      "successModalAction": "Go to Data Incoming"
    }
  },
  "error": {
    "pageNotFound": "Page not found (404)",
    "unauthorized": "Unauthorized access (403)"
  },
  "helpWidget": {
    "askSupport": "Ask support",
    "chatboxTitle": "Help chat",
    "chatboxSubtitle": "Telekom Data Intelligence Hub support",
    "defaultMessage": {
      "body": "Hi there, welcome to the Telekom Data Intelligence Hub support channel. Please select an option below."
    },
    "whatCanWeHelpWith": "What can we help you with today?",
    "suggestedAnswers": {
      "knowledgeBase": "Knowledge-base, documentation and FAQs",
      "contactSupport": "Contact support",
      "contactSales": "Contact sales"
    }
  },
  "partnerNetworkManagement": {
    "title": "Partner network management",
    "hint": "This page will allow users to invite and monitor invitation status for your partner organizations to Telekom Data Intelligence Hub",
    "noInvitations": {
      "title": "You have not invited any organizations yet",
      "actionButtonText": "Invite an organization"
    },
    "overview": {
      "title": "Partner invitations overview",
      "actionButtonText": "Invite organization(s)",
      "dateOfInvitation": "Date of invitation",
      "resendInvitation": "Resend invitation",
      "deleteInvitation": "Delete invitation",
      "resendReminderInfo": "You can resend the invitation as soon as it has been 24 hours since the last invitation email.",
      "invitedBy": "Invited by"
    },
    "information": {
      "title": "Important information",
      "description": "The contact person will assume the role of \"Administrator\" within the Telekom Data Intelligence Hub. As an administrator, they will have the authority to add or remove users from their own organization, oversee roles and make commercial decisions regarding services offered by us. We will convey the next steps for organization onboarding to the contact person via email, and you can monitor the progress under the \"Partner invitations overview\" page."
    },
    "invite": {
      "additionalText": "Please consider whether you'd like to invite organizations one by one, or in bulk - and make your selection below.",
      "bulkInvite": "Bulk invitations",
      "bulkInviteHint": "If you wish to conduct bulk invitation (>5 invites), please select this option.",
      "limitationHint": "Limit: Maximum 5 organizations. To add more, kindly select \"Bulk invitations\"",
      "singleInvite": "Single invite",
      "singleInviteHint": "To invite 5 organizations or less, please select this option.",
      "orgNameLabel": "Enter partner organization name",
      "emailLabel": "Enter contact person email address"
    },
    "inviteStatus": {
      "INVITED": "Invited",
      "COMPLETED": "Accepted",
      "FAILED": "Rejected"
    },
    "upload": {
      "hint": "To invite multiple organizations, please do the following:",
      "step1": "Download the template file from the link below",
      "step2": "Fill in the provided file with details of the invitee(s)",
      "step3": "Upload the file in the provided area",
      "downloadTemplate": "Download template",
      "templateHeader": {
        "organizationName": "organization_name",
        "email": "email"
      },
      "templateFileName": "bulk_invitations_template.csv",
      "learnMore": "If you want to learn more about bulk invitation, please {clickHere}.",
      "clickHere": "click here"
    },
    "report": {
      "invitationsInProgress": "Organization invitations are in progress",
      "invitationsSent": "Invitations sent",
      "error": {
        "errorsInInvitations": "Errors in {result} invitations sent",
        "single": {
          "invalidEmail": "Email address is invalid",
          "pleaseEnterValidEmail": "Please enter a valid email address",
          "pleaseEnterValidOrganization": "Please enter a valid organization name",
          "emailUnique": "Email must be unique"
        },
        "bulk": {
          "allowedFileExtension": "Only files with the .csv extension are allowed",
          "fileIsTooBig": "The file is too big. You can upload file up to 1 MB",
          "formatMismatch": "The file does not match expected format. Please use provided template and try again",
          "duplicateEmail": "File has {number} {duplicate}",
          "duplicate": "duplicate | duplicates",
          "fileHasNoEntries": "File has no entries",
          "invalidEmailAddresses": "{number} email addresses are invalid.",
          "invalidOrganizationNames": "{number} organizations have invalid names.",
          "organizationAlreadyInvited": "{number} organizations have already been invited by your organization.",
          "kindlyDownloadReport": "Kindly download report to view more details"
        },
        "processing": {
          "DUPLICATE_EMAILS": "Duplicate emails detected.",
          "EMAIL_ALREADY_EXIST": "Email already exists.",
          "SOMETHING_WENT_WRONG": "Something went wrong.",
          "INVALID_EMAIL_FORMAT": "Invalid email format."
        }
      },
      "template": {
        "fileName": "bulk_invitations_report.csv"
      }
    },
    "abort": {
      "title": "Abort invitation",
      "confirmText": "Are you sure you want to close this process?",
      "informationText": "Your invitations will not be sent."
    },
    "switchForm": {
      "title": "Discard changes",
      "confirmText": "Are you sure you want to switch forms?",
      "informationText": "Your changes will not be retained."
    }
  },
  "discover": {
    "welcome": {
      "title": "Discover data offers from our trusted partners and providers",
      "description1": "Make data driven decisions.",
      "description2": "Data plays a pivotal role in business operations, serving various essential functions such as backbone for decision making, market segmentation and lead generation. Data can make or break your product development, optimize your supply chain logistics, or feed your AI the insights it needs to draw the conclusions you need to advance your goals.",
      "description3": "We in Telekom's Data Intelligence Hub offer a unified platform for data providers across Germany. Explore your options and dive in: you might find an excellent fit for your data needs. We constantly expand our network of Data Providers, so even if your exact needs aren't covered by our current Data Offering, don't be a stranger: your perfect data fit might be days away.",
      "buttonLabel": "Explore Data Products"
    },
    "dataOffers": {
      "title": "Data Offers",
      "filter": {
        "subjectAreas": "Subject areas",
        "dataOfferingType": "Data offering type",
        "dataProvider": "Data provider"
      },
      "tiles": {
        "productType": "Configurable data product",
        "dataspaceName": "Data Intelligence Hub Dataspace",
        "subjectArea": "Subject area",
        "massmovementAnalytics": "Massmovement analytics",
        "termsOfUse": "Terms of use",
        "licensedRestricted": "Licensed, restricted",
        "viewOffer": "View Offer",
        "product1": {
          "name": "Traffic Analytics by Motion Data",
          "summary": "Detailed movement behavior statistics in Germany, including direction of movement, origin, destination, allowing analytics on different spatial levels in any region."
        },
        "product2": {
          "name": "Traffic Analytics PRO by Motion Data",
          "summary": "Detailed movement measurements in Germany, including direction of movement, origin, destination, allowing analytics and comparisons on and between different spatial levels at any time and in any region."
        },
        "product3": {
          "name": "Visitor Analytics PRO by Motion Data",
          "summary": "Analyze information about visitation behavior due to public movement patterns, including total user counts on a daily and hourly basis, sociodemographic insights, and the origin details of visitors in any region across Germany. Next level of analysis options for on-site visitation behavior, combining the advantages of one of the largest sample sizes and unprecedented spatial precision."
        }
      }
    }
  }
}